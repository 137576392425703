import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


import styles from "./page.module.css"

const Page = (props: PageProps<GatsbyTypes.PageBySlugQuery>) => {
  return (
    <Layout location={props.location} shouldContainer={true}>
      <h1 className={styles.header}>{props.data.contentfulPage?.title}</h1>
      {//@ts-ignore 
        documentToReactComponents(props.data.contentfulPage?.body?.json)
      }

    </Layout>
  )
}

export default Page



export const pageQuery = graphql`
query PageBySlug($slug: String!) {
  contentfulPage(slug: { eq: $slug }) {
    id
    title
    body {
      json
    }
  }
}
`
